import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';

class Hakkimizda extends Component {
    componentDidMount() {
        const text = `Hakkimizda: ${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${window.navigator.language}, ${window.navigator.platform}, ${window.navigator.vendor}, ${window.navigator.appName}, ${window.navigator.userAgent}, ${window.navigator.product}, ${window.navigator.productSub}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render() {
        return (  
            <div>
                <HeaderSub header='Hakkımızda'/>
                <div className="Page">  
                    <div className="PageText">
                        LGS sınavına hazırlanan öğrencilere yönelik olarak 
                        tasarlanan sitemizde, yeni nesil sınav sorularını online 
                        olarak çözebilirsiniz.
                    </div>
                    <div className="PageSection">
                        Geliştirme Ekibimiz
                    </div>
                    <div className="PageText">
                        Ekibimiz, konusunda uzman öğretmenler 
                        arasından özenle seçtiğimiz profesyonellerden oluşmaktadır.
                    </div>
                    <div className="PageSection">
                        Soru Bankası
                    </div>
                    <div className="PageText">
                        Soru bankamızda bulacağınız tüm sorular yeni nesil soru tipine 
                        uygun olarak hazırlanmıştır. Sorular; 'Öğren', 'Uygula' ve 
                        'Başar' adları verilerek aşamalandırılmış ve 
                        öğrencilerimizin gelişim sürecinde onlarla birlikte 
                        yürüyecek şekilde tasarlanmıştır.
                    </div>
                    <div className="PageSection">
                        Dersler
                    </div>
                    <div className="PageText">
                    Soru bankası Matematik, Fen Bilimleri, Türkçe ve İnkılap
                    Tarihi derslerine ilişkin soruları içermektedir.
                    </div>
                    <div className="PageBold">
                        Kimler İçin Uygun?
                    </div>
                    <div className="PageText">
                        LGS sınavına hazırlanan her öğrenci; müfredattaki konularla
                        ilgili soruları çözmek, çözerken pekiştirmek ve test çözme 
                        pratiğini geliştirmek üzere bu siteden yararlanabilir. 
                        Testler, süreli ya da süresiz olarak çözülebilir. Dolayısıyla bu 
                        site, zamanlı testlerde kendini geliştirmek isteyen öğrenciye de, 
                        zamana bakmaksızın sadece soru çözmek isteyen öğrenciye de hitap etmektedir.
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Hakkimizda);

import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

import onlineImg from './assets/banner.png'

class Banner extends Component {
    handleClick = () => {
        window.location.href = 'https://my.derZane.com';
    }

    render () {
        return (
            <div className="Banner">
                <img src={onlineImg} alt="derZane.com" />
                <div className="BannerHeader">
                    Başarının Sırrı    
                </div>
                <div className="BannerText">
                    Tamamı yeni nesil sorulardan oluşan testleri cevaplamak için hemen üye ol.
                    E-postanı doğruladıktan sonra test çözmeye başlayabilirsin. Arkadaşlarını 
                    da davet ederek onların da soru bankasından yararlanmasını sağlayabilirsin.
                </div>
                <div className="BannerHeader">
                    <Button variant="secondary" onClick={this.handleClick}>Hemen Üye Ol</Button>
                </div>
            </div>
        )
    }
}

export default Banner;
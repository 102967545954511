import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';

class Fiyatlandirma extends Component {
    componentDidMount() {
        const text = `Fiyatlar: ${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${window.navigator.language}, ${window.navigator.platform}, ${window.navigator.vendor}, ${window.navigator.appName}, ${window.navigator.userAgent}, ${window.navigator.product}, ${window.navigator.productSub}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render() {
        return (  
            <div>
                <HeaderSub header='Üyelik Ücreti'/>  
                <div className="Page">      
                    <div className="PageText">
                    Derzane soru bankası 8. sınıf için matematik, fen, türkçe ve inkılap tarihi  
                    derslerinin LGS sınavı kapsamında işlenen tüm konularını içermektedir. 
                    </div>
                    <div className="PageText">
                    Sistemin kullanımı bir süreliğine ÜCRETSİZdir.
                    </div>
                    <div className="PageText">
                    Hemen üye olarak testleri çözmeye başlayabilirsin. Arkadaşlarını da davet ederek 
                    onların da derZane'den ücretsiz yaralanmasını sağlayabilirsin.
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Fiyatlandirma);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';

class Iletisim extends Component {
    componentDidMount() {
        const text = `Iletisim: ${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${window.navigator.language}, ${window.navigator.platform}, ${window.navigator.vendor}, ${window.navigator.appName}, ${window.navigator.userAgent}, ${window.navigator.product}, ${window.navigator.productSub}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render() {
        return (  
            <div>
                <HeaderSub header='İletişim'/>
                <div className="Page">
                    <div className="PageSection">
                        
                    </div>
                    <div className="PageText">
                        Bize ulaşmak isterseniz&nbsp;
                        <a className="FooterCenterLink" href="mailto:iletisim@derzane.com">iletisim@derzane.com</a>
                        &nbsp;adresine e-posta atabilirsiniz.
                    </div>
                    {/* 
                    <div className="PageSection">
                        WhatsApp
                    </div>
                    <div className="PageText">
                        ......
                    </div>
                    <div className="PageSection">
                        E-Mail
                    </div>
                    <div className="PageText">
                        iletisim@derzane.com
                    </div>
                    <div className="PageSection">
                        Facebook
                    </div>
                    <div className="PageText">
                    https://facebook/derzane.medya
                    </div>
                    <div className="PageSection">
                        Instagram
                    </div>
                    <div className="PageText">
                    https://instagram/derzane.medya
                    </div>
                    <div className="PageSection">
                        Twitter
                    </div>
                    <div className="PageText">
                    https://twitter/derzane.medya
                    </div>
                    <div className="PageSection">
                        Adres
                    </div>
                    <div className="PageText">
                        <div>ABC Mah</div>
                        <div>BDC Sok. 10 No</div>   
                        <div>Nişantaşı Istanbul</div>
                    </div>
                    <div className="PageSection">
                        Telefon
                    </div>
                    <div className="PageText">
                        .....
                    </div>
                    */}
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Iletisim);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';

class SSS extends Component {
    componentDidMount() {
        const text = `SSS: ${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${window.navigator.language}, ${window.navigator.platform}, ${window.navigator.vendor}, ${window.navigator.appName}, ${window.navigator.userAgent}, ${window.navigator.product}, ${window.navigator.productSub}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render() {
        return (  
            <div>
                <HeaderSub header='Sıkça Sorulan Sorular'/>
                <div className="Page">      
                    <div className="PageText">
                    Derzane soru bankası LGS'ye hazırlanan öğrencilere yardım etmek amacı ile geliştirilmiştir. Derzane soru bankası ile ilgili sıkça sorulan soruları sizin için derledik.
                    </div>
                    <div className="PageSection">
                    Soru bankasında hangi sınıfın dersleri mevcut?
                    </div>
                    <div className="PageText">
                    8. sınıf derslerini soru bankasında bulabilirsiniz.
                    </div>
                    <div className="PageSection">
                    Derzane soru bankasında hangi dersler bulunmaktadır?
                    </div>
                    <div className="PageText">
                    Derzane soru bankası 8. sınıf için matematik, fen bilimleri, türkçe ve inkılap tarihi bünyesinde barındırmaktadır.
                    </div>
                    <div className="PageSection">
                    Derzane'ye üye olmak için ne kadar ücret ödemem gerekir?
                    </div>
                    <div className="PageText">
                    Derzane'den şimdilik ücretsiz olarak yararlanılabilir.
                    </div>
                    <div className="PageSection">
                    Kayıt olmak için ne yapmam lazım?
                    </div>
                    <div className="PageText">
                    Menüdeki 'Üye Bölümü' seçeneğini tıkladıktan sonra geçerli bir e-posta adresi ve sadece kendi bileceğiniz bir şifre girmeniz ve 'Yeni Üye' butonunu tıklamanız yeterlidir. Sistem e-posta adresinize otomatik olarak bir mesaj gönderecek ve mesajdaki butona tıklayarak kullanıcı adresinizi doğrulamanızı bekleyecektir.
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(SSS);
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import derZaneLogo from './assets/derzane-logo-2000.png';
import menuImg from './assets/menubar.png';

class Menu extends Component {
    state = {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        smallMenuLimit: 768
    }

    getMenuSize = () => {
        this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
    }

    componentDidMount() {
        window.addEventListener('resize', this.getMenuSize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getMenuSize);
    }

    render() {
        return (
            <div className="Menu">
                <div className="MenuLogo">
                    <Link to='/'>
                        <img src={derZaneLogo} width="100%" alt='derZane.com' />
                    </Link>
                </div>            
                <div className="MenuContainer">
                    {
                        this.state.innerWidth >= this.state.smallMenuLimit ?
                        <div>
                            <Link className="MenuItem" to='/hakkimizda'>Hakkımızda</Link>
                        </div> : null
                    }
                    {
                        this.state.innerWidth >= this.state.smallMenuLimit ?
                        <div>
                            <Link className="MenuItem" to='/fiyatlandirma'>Üyelik Ücreti</Link>
                        </div> : null
                    }
                    {
                        this.state.innerWidth >= this.state.smallMenuLimit ?
                        <div>
                            <Link className="MenuItem" to='/iletisim'>İletişim</Link>
                        </div> : null
                    }
                    {
                        this.state.innerWidth >= this.state.smallMenuLimit ?
                        <div>
                            <a className="MenuItem" href="https://my.derzane.com">Üye Bölümü</a>
                        </div> : null
                    }
                </div>
                <div className="MenuRightContainer">
                    <div className="MenuShort">
                        <img className="MenuIcon" src={menuImg} alt="derZane.com" />
                        <div className="MenuShortContent">
                            <div className="MenuShortItem"><Link className="MenuSubItemItem" to="/hakkimizda/">Hakkımızda</Link></div>
                            <div className="MenuShortItem"><Link className="MenuSubItemItem" to="/fiyatlandirma/">Fiyatlar</Link></div>
                            <div className="MenuShortItem"><Link className="MenuSubItemItem" to="/iletisim/">İletişim</Link></div>
                            <div className="MenuShortItem"><a className="MenuSubItemItem" href="https://my.derzane.com">Üye Bölümü</a></div>
                        </div>
                    </div>
                </div>
            </div>
        )        
    }
}

export default Menu;
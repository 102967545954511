import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { Link } from 'react-router-dom';

class ContactForm extends Component {
    state = {
        konu: '',
        ad: '',
        soyad: '',
        phone: '',
        email: '',
        mesaj: ''
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    checkValidity = () => {
        let { ad, soyad, phone, email, konu, mesaj } = this.state;

        if ((ad !== '' || soyad !== '') &&
            (phone !== '' || email !== '') &&
            (konu !== '' || mesaj !== ''))
            return true;

        return false;
    }

    render() {
        return (
            <div className="ContactForm">
                <div className="ContactHeader">
                    İletişim Formu
                </div>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '80px' }}>Ad</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="ad"
                        value={this.state.ad}
                        onChange={this.handleChange}
                        placeholder='Adınızı giriniz'
                    />
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '80px' }}>Soyad</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="soyad"
                        value={this.state.soyad}
                        onChange={this.handleChange}
                        placeholder='Soyadınızı giriniz'
                    />
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '80px' }}>Telefon</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleChange}
                        placeholder='Telefonunuzu giriniz'
                    />
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '80px' }}>E-Posta</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder='E-Posta adresinizi giriniz'
                    />
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '80px' }}>Konu</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        as="select"
                        value={this.state.konu}
                        onChange={this.handleChange}
                        name='konu'
                    >
                        <option key='XX' value='XX'>Konu Seçiniz</option>
                        <option key='UY' value='Üyelik Hakkında'>Üyelik Hakkında</option>
                        <option key='OD' value='Ödeme Hakkında'>Ödeme Hakkında</option>
                        <option key='TS' value='Testler Hakkında'>Testler Hakkında</option>
                        <option key='DD' value='Diğer'>Diğer</option>
                    </FormControl>
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '80px' }}>Mesaj</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="mesaj"
                        as="textarea"
                        rows={5}
                        value={this.state.mesaj}
                        onChange={this.handleChange}
                        placeholder='Mesajınızı giriniz'
                    />
                </InputGroup>
                <div className="ContactButton">
                    {/* 
                        <Link className="MyButton btn-secondary"
                            to={`/iletisim-formu-alindi?ileten=Z&ad=${this.state.ad}&soyad=${this.state.soyad}&tel=${this.state.phone}&email=${this.state.email}&konu=${this.state.konu}&mesaj=${this.state.mesaj}`}
                        >
                            Gönder
                        </Link>
                        :
                        <Link
                            className="MyButton btn-secondary"
                            to=""
                        >
                            Lütfen formu doldurunuz
                        </Link>
                        */}
                    {this.checkValidity() ? 
                        <Link className="MyButton btn-secondary"
                        to={`/iletisim-formu-alindi?ileten=Z&ad=${this.state.ad}&soyad=${this.state.soyad}&tel=${this.state.phone}&email=${this.state.email}&konu=${this.state.konu}&mesaj=${this.state.mesaj}`}
                    >
                        Gönder
                    </Link> :
                        <Link
                            className="MyButton btn-secondary"
                            to=""
                        >
                            Lütfen formu doldurunuz
                        </Link>
                    }
                </div>
            </div>
        )        
    }
}

export default ContactForm;
import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Menu from './Menu';
import Banner from './Banner';
import ContactForm from './ContactForm';
import Footer from './Footer';
import Tail from './Tail';

import AnaSayfa from './AnaSayfa';
import Hakkimizda from './Hakkimizda';
import Fiyatlandirma from './Fiyatlandirma';
import Duyurular from './Duyurular';
import SSS from './SSS';
import ContactFormFiled from './ContactFormFiled';
import Iletisim from './Iletisim';
import GizlilikPolitikasi from './GizlilikPolitikasi';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Menu />
        <Switch>
          <Route path='/hakkimizda' component={Hakkimizda} />
          <Route path='/fiyatlandirma' component={Fiyatlandirma} />
          <Route path='/duyurular' component={Duyurular} />
          <Route path='/sik-sorulan-sorular' component={SSS} />
          <Route path='/iletisim-formu-alindi' component={ContactFormFiled} />
          <Route path='/iletisim' component={Iletisim} />
          <Route path='/gizlilik-politikasi' component={GizlilikPolitikasi} />
          <Route path='/' component={AnaSayfa}/>
        </Switch>
        <Banner />
        <ContactForm />
        <Footer />
        <Tail />
      </BrowserRouter>
    )
  }
}

export default App;
import React, { Component } from 'react';

class CoverItem extends Component {
    state = {
        defaultBorderColor: 'BorderDefaultColor',
    }

    handleClick = () => {
        if (this.props.callPage)
            this.props.callPage(this.props.redirect);
    }

    handleMouseEnter = () => {
        this.setState({ defaultBorderColor: 'BorderHighlightColor' });
    }

    handleMouseLeave = () => {
        this.setState({ defaultBorderColor: 'BorderDefaultColor' });
    }

    render() {
        return (
            <div className="Cover">
                <div
                    className={"CoverItem " + this.state.defaultBorderColor}
                    onClick={this.handleClick}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                > 
                    <div className="CoverItemImage">
                        {
                            this.props.image !== "" ?
                            <img src={this.props.image} alt="derZane.com" /> : null
                        }
                    </div>
                    <div className="CoverItemHeader">
                        {this.props.header}
                    </div>
                </div>
            </div>
        )
    }
}

export default CoverItem;
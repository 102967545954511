import { BACKEND } from '../secrets/config';
import { ACCOUNT } from './types';

export const invokeLogger = ({ id, text }) => dispatch => {
    dispatch({ type: ACCOUNT.INVOKELOGGER });

    return fetch(`${BACKEND.ADDRESS}/Account/Logger`,
        {
            method: 'POST',
            body: JSON.stringify({ id, text }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.INVOKELOGGER_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.INVOKELOGGER_SUCCESS,
                    message: json.message
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.INVOKELOGGER_ERROR,
            message: error.message
        }));
}

export const windowCheck = ({
    id,
    serialNumber,
    dateOfFirstVisit,
    accountId,
    language,
    vendor,
    userAgent,
    cookie
}) => dispatch => {
    dispatch({ type: ACCOUNT.WINDOWCHECK });

    return fetch(`${BACKEND.ADDRESS}/Account/WindowCheck`,
        {
            method: 'POST',
            body: JSON.stringify({
                id,
                serialNumber,
                dateOfFirstVisit,
                accountId,
                language,
                vendor,
                userAgent,
                cookie
            }),
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include' 
        })
        .then(response => response.json())
        .then(json => {
            if (json.type === 'error') {
                dispatch({
                    type: ACCOUNT.WINDOWCHECK_ERROR,
                    message: json.message
                });
            } else {
                dispatch({
                    type: ACCOUNT.WINDOWCHECK_SUCCESS,
                    message: json.message,
                    visitor: json.visitor
                });
            }
        })
        .catch(error => dispatch({
            type: ACCOUNT.WINDOWCHECK_ERROR,
            message: error.message
        }));
}
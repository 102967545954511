import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';

class GizlilikPolitikasi extends Component {
    componentDidMount() {
        const text = `GizlilikPolitikasi: ${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${window.navigator.language}, ${window.navigator.platform}, ${window.navigator.vendor}, ${window.navigator.appName}, ${window.navigator.userAgent}, ${window.navigator.product}, ${window.navigator.productSub}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render() {
        return (  
            <div>
                <HeaderSub header='Gizlilik Politikası'/>
                <div className="Page"> 
                    <div className="PageSection">
                    Genel Konular
                    </div>     
                    <div className="PageText">
                    Bundan böyle derZane olarak anılacak www.derzane.com, web sitesini ziyaret edenlerin gizliliğini korumayı ilke olarak benimsemiştir. 
                    </div>
                    <div className="PageText">
                    Bu kapsamda derZane olarak, kişisel verilerin hukuka uygun işlenmesi adına 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun“) hükümlerine, Kişisel Verileri Koruma Kurulu (“Kurul“) kararlarına ve uygun düştüğü ölçüde Avrupa Birliği Veri Koruma Tüzüğü (“GDPR“) hükümlerine uyum sağlamak için gerekli her türlü tedbir ve aksiyonu alıyoruz. 
                    </div>
                    <div className="PageText">
                    Sizleri, kullanıcılara ilişkin ne tür bilgilerin derZane tarafından işlendiğini ve bunların nasıl kullanıldığı konusunda bilgilendirmek istiyoruz. Aynı zamanda sizleri, veri sahibi olarak ilgili haklarınızı nasıl kullanabileceğinize dair bilgilendireceğiz.
                    </div>
                    <div className="PageText">
                    İşbu Gizlilik ve Çerez Politikası‘na ilişkin olası değişiklikler bu sayfada yayımlanacaktır. Bu çerçevede, hangi bilgilerin saklandığına ve bu bilgilerin nasıl kullanıldığına dair güncel olarak bilgi almanıza olanak sağlanacaktır.
                    </div>
                    <div className="PageText">
                    İşbu Gizlilik ve Çerez Politikası, derZane sitesinden bağlantı uzantısı verilmiş başka web siteleri için geçerli değildir.
                    </div>
                    <div className="PageSection">
                    Kişisel Verilerin Toplanması, Kullanımı ve İşlenmesi
                    </div>
                    {/*
                    <div className="PageText">
                    Sorumlu kuruluşun adı: Derzane A.Ş.
                    </div>
                    <div className="PageText">
                    Sorumlu kuruluşun adresi: ..... Istanbul,Türkiye
                    </div>
                    */}
                    <div className="PageText">
                    derZane, ilgili kişisel verileri bu web sitesini kullanıcılara sunmak ve bu sitenin uygun şekilde çalıştığının ve gerekli ölçüde güvenliğin sağlandığının temin edilmesi doğrultusunda kullanır. Bu kapsam dışında kalan ve veriler üzerinde gerçekleştirilen her işlem, diğer yasal yükümlülükler, zinler, derZane’in meşru menfaatinin bulunduğu haller zemininde ya da ilgili kullanıcının derZane’e verdiği açık rıza çerçevesinde gerçekleşmektedir. 
                    </div>
                    <div className="PageText">
                    derZane, ilgili verileri özel olarak şu amaçlar doğrultusunda saklar ve işler:
                    </div>
                    <div className="PageText">
                    Kullanıcılar web sitesini ziyaret ettiğinde derZane belirli verileri otomatik olarak toplar ve saklar. Bu verilere: Talep edilen içeriği (örn. özellikle içeriği, metinleri, görselleri ve indirme için sağlanan veri dosyalarını vb.) iletebilmek için gerek duyulan, ilgili uç cihaza tahsis edilmiş IP adresi ya da cihaz kimliği, kullanıcıların web sitesi bağlamındaki aktiviteleri, ilgili uç cihaz türü, kullanılan tarayıcı türü ve kullanım tarihi ve saati dahildir.
                    </div>
                    <div className="PageText">
                    derZane bu bilgileri amaç dışında kullanımı tespit ve takip edebilmek üzere saklamaktadır. 
                    </div>
                    <div className="PageText">
                    Aynı zamanda derZane, hizmetlerin sunumunu, özelliklerini, işlevlerini ve genel yönetim görevlerini geliştirmek için de bu bilgileri kullanır.
                    </div>
                    <div className="PageText">
                    Bunların yanında derZane, yukarıda verilen amaçlar doğrultusunda söz konusu verilere artık gerek duyulmadığında, IP adresleri de dahil olmak üzere kullanımdaki verileri usule aykırı gecikme olmaksızın siler ya da anonim hale getirir.
                    </div>
                    <div className="PageText">
                    İlgili verilerin işlenmesi ve kullanımı, (1) işlemenin web sitesinin sunulabilmesi için gerekli olduğu; ya da (2) derZane’in, web sitesinin işlevselliğini ve sorunsuz biçimde çalışmasını, aynı zamanda da kullanıcıların gereksinimlerine uygun olmasını temin eden ve geliştiren meşru bir menfaate sahip olduğu durumlarda, bu işlemlerin yapılabilmesine imkan sağlayan ilgili yasa hükümlerinin oluşturduğu zemin doğrultusunda gerçekleştirilmektedir.
                    </div>
                    <div className="PageText">
                    Toplanan verilerin alıcılarına; sorumlu bulunan kurum içi departmanların üyeleri, derZane’e bağlı diğer şirketler, dış hizmet sağlayıcıları (örn. hosting ve içerik yönetimi, pazarlama ajansları, sağladıkları hizmetler dolayısıyla gerekli olması halinde diğer üçüncü taraf sağlayıcılar), ilgili yükümlülükleri ve yetkileri çerçevesinde; örneğin, resmi bir talepte bulunulması ya da derZane’in sahip olduğu hakları tespit etmek, uygulamak ya da savunmak üzere gerekli olması halinde ilgili yetkililer ve derZane şirketlerinin mevcut hissedarları ya da satış, birleşme ya da satın alma durumunda gelecekteki hissedarları dahil olabilir.
                    </div>
                    <div className="PageSection">
                    Kişisel Verilerin İşlenme Amaçları
                    </div>
                    <div className="PageText">
                    İletişim / Müşteri Talep Formu
                    </div>
                    <div className="PageText">
                    İletişim bilgilerinizi web sitemizde yer alan “Bize ulaşın” isimli iletişim formu üzerinden veya ilgili sekmede yer alan çağrı merkezi numaralarımız aracılığıyla bizimle paylaşmış olabilirsiniz.
                    </div>
                    <div className="PageText">
                    Size ait verileri talebinize cevap vermek amacıyla kullanacağız.
                    </div>
                    <div className="PageText">
                    Verilerinizin işlenmesi ve kullanımı, mevcut talebinizi karşılamak için işlemenin gerekli olması halinde bunun yapılabilmesine imkan sağlayan yasa hükümlerinin ve/veya derZane’in meşru menfaatinin oluşturduğu zemin doğrultusunda gerçekleştirilmektedir.
                    </div>
                    <div className="PageText">
                    Herhangi bir açık rıza beyanı vasıtasıyla daha uzun bir süre için onay vermediğiniz durumda, verileri yalnızca yukarıda verilen hedefi gerçekleştirmek için gerekli süre ya da herhangi bir yasal saklama zorunluluğunun gerektirdiği dönem boyunca saklayacağız.
                    </div>
                    <div className="PageSection">
                    Çerezler ("Cookie"ler), Pikseller ve Benzer Teknolojiler
                    </div>
                    <div className="PageText">
                    Bu web sitesi, çerezleri ve benzeri teknolojileri kullanmaktadır. Çerezler, web sitemizi kullanmak için gerekli olan ve tarayıcınız tarafından sabit diskinize geçici olarak depolanan küçük veri birimleridir. Bir çerez genellikle, rastgele şekilde oluşturulmuş bir sayıdan oluşan ve cihazınıza depolanan benzersiz bir tanımlayıcı içerir. Bu tanımlayıcıların bazıları web sitesi oturumunuz sona erdiğinde geçerliliğini yitirirken, bazıları ise daha uzun süreler boyunca cihazınızda kalabilir. Teknik açıdan gerekli çerezler otomatik olarak kullanılacaktır. Diğer çerezler (ya da benzeri teknolojiler) yalnızca ön onayınız üzerine uygulanacaktır.
                    </div>
                    <div className="PageText">
                    Farklı türde çerezler bulunmaktadır. Birinci taraf çerezleri, ziyaret edilen web sitesi tarafından yerleştirilir ve yalnızca o web sitesi tarafından okunabilir. Üçüncü taraf çerezleri ise, kullandığımız çeşitli servisler için hizmet aldığımız diğer kuruluşlar tarafından yerleştirilmektedir. Buna örnek olarak, web sitemizde beğenilen ya da beğenilmeyen içerikleri tespit edebilmek amacıyla bizim adımıza çerezler yerleştiren harici analitik hizmeti sağlayıcıları kullanıyoruz. Buna ek olarak, ziyaret ettiğiniz web sitesi, örneğin, YouTube gibi bir kaynaktan alınmış gömülü bir içerik barındırabilir ve çerezler bu kaynak tarafından da yerleştirilebilir.
                    </div>
                    <div className="PageText">
                    Bir piksel genellikle, içerik kişiselleştirme ya da tarayıcı deneyimini daha etkili ve kolay hâle getirme amacı doğrultusunda bir web sitesine yerleştirilen ve IP adresi, tarayıcı türü, erişim zamanı ya da önceden ayarlanan çerezler gibi belirli verileri toplayan yalnızca 1 piksel x 1 piksel ölçülerindeki şeffaf bir görseldir. Piksellerin çerezleri oluşturup yönetmesi nedeniyle sıklıkla çerezlerle birlikte kullanılırlar.
                    </div>
                    <div className="PageText">
                    Web sitesi, onay vermeniz halinde cihazın işletim sistemi, tarayıcısı, yüklü yazı tipleri, IP adresi, eklentileri ve daha detaylı bilgileri toplayacaktır. Bu sayede cihaz, kullanıcıya ilişkin pazarlama amaçları doğrultusunda yeniden tanınabilir.
                    </div>
                    <div className="PageText">
                    Çerezler ve web sitemizde kullanılan diğer teknolojiler ve bunların kullanım amaçları hakkında daha detaylı bilgi almak için web sitemizde yer alan “Çerezler” ve “Çerez ayarları” bölümlerine göz atabilirsiniz. Aynı zamanda bu bölümler içerisinden, ilgili çerez ayarlarını devre dışı bırakarak mevcut onaylarınızı bundan böyle geçerli olmak üzere geri çekebilirsiniz.
                    </div>
                    <ul className="PageBullet">
                    <li>Mapp Intelligence (önceki adıyla Webtrekk)</li>
                    <li>Google Ads</li>
                    <li>Google Campaign Manager 360 (önceki adıyla DoubleClick)</li>
                    <li>Sosyal Medya</li>
                    </ul>
                    <div className="PageSection">
                    Veri Sahibi Olarak Haklarınız
                    </div>
                    <div className="PageText">
                    Her zaman için verilerinize erişme hakkınızı kullanabilirsiniz. Buna ek olarak, ilgili şartlar yerine getirildiği takdirde Kanun’un 11. maddesinde yer alan haklarınızı ve şu haklarınızı da kullanabilirsiniz:
                    </div>
                    <ul className="PageBullet">
                    <li>Düzeltme hakkı</li>
                    <li>Silme hakkı</li>
                    <li>İşlemeyi kısıtlama hakkı</li>
                    <li>Yetkili veri koruma denetim kurumu üzerinden bir şikayet oluşturma hakkı</li>
                    <li>Veri taşınabilirliği hakkı (25 Mayıs 2018 itibariyle)</li>
                    <li>İtiraz hakkı</li>
                    </ul>
                    <div className="PageText">
                    Kişisel verilerinizin, derZane’in yasal bir faydaya sahip olduğu zeminde işlenmesine ilişkin etkinlikler söz konusu olduğunda, içinde bulunduğunuz özel durum sonucunda oluşan gerekçeler dolayısıyla, her zaman için kişisel verilerinizin işlenmesine itiraz hakkınız bulunmaktadır. derZane, verilerin işlenmesine ilişkin olarak korunması gereken ve çıkarlarınızın, haklarınızın ve özgürlüklerinizin üzerinde yer alan önemli bir gerekçe olduğunu kanıtlayamadığı ya da bu işlem yasal hak taleplerde bulunmak, bu talepleri kullanmak ya da savunmak gibi amaçlara hizmet etmediği sürece derZane verilerin işlenmesini durduracaktır
                    </div>
                    <div className="PageText">
                    Kişisel verilerinizin işlenmesine onay vermeniz halinde, bundan böyle geçerli olmak üzere bu onayınızı geri çekebilirsiniz.
                    </div>
                    {/* 
                    <div className="PageText">
                    Kanun kapsamında, kişisel verilerinizle ilgili her türlü talep, şikâyet ve önerilerinizi, kimliğinizi tevsik edici belgeler ve talebinizi içeren dilekçeniz ile aşağıdaki adresimize ulaştırabilir veya güvenli elektronik imza kullanmak suretiyle şirketimizin derZane@hs01.kep.tr kayıtlı elektronik posta adresine gönderebilirsiniz.
                    </div>
                    <div className="PageText">
                    Adresimiz: 
                    Derzane A.Ş.
                    ....... İstanbul TÜRKİYE
                    </div>
                    */}
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(GizlilikPolitikasi);

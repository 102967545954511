import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import image1 from './assets/clock-top.jpg';
import image2 from './assets/results.jpg';
import image3 from './assets/studentlesson.jpg';
import image4 from './assets/studentlaptop.jpg';
import image5 from './assets/ogrenci.jpg';
import image6 from './assets/test.jpg';

import mobile1 from './assets/clock-top_mobile.jpg';
import mobile2 from './assets/results_mobile.jpg';
import mobile3 from './assets/studentlesson_mobile.jpg';
import mobile4 from './assets/studentlaptop_mobile.jpg';
import mobile5 from './assets/ogrenci_mobile.jpg';
import mobile6 from './assets/test_mobile.jpg';

class Header extends Component {
    render() {
        return (
            <div className="Header">
                <Carousel>
                    <Carousel.Item>
                        <img className="HImgNormal" src={image1} width="100%" alt="derZane.com"/>
                        <img className="HImgMobile" src={mobile1} width="100%" alt="derZane.com"/>
                        <Carousel.Caption>
                            <div className="HeaderText">
                                <div className="HeaderText1">LGS yolculuğunda derZane'ye katıl</div>
                                <div className="HeaderText2">İhtiyacın olan yeni nesil test sorularını burada bulabilirsin</div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="HImgNormal" src={image2} width="100%" alt="derZane.com"/>
                        <img className="HImgMobile" src={mobile2} width="100%" alt="derZane.com"/>
                        <Carousel.Caption>
                        <div className="HeaderText">
                            <div className="HeaderText1">Konusunda uzman öğretmenler</div>
                            <div className="HeaderText2">Yeni nesil soruları senin için hazırladılar</div>
                        </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="HImgNormal" src={image3} width="100%" alt="derZane.com"/>
                        <img className="HImgMobile" src={mobile3} width="100%" alt="derZane.com"/>
                        <Carousel.Caption>
                        <div className="HeaderText">
                            <div className="HeaderText1">2.696 soru ile bilgilerini yokla</div>
                            <div className="HeaderText2">Matematik, Fen Bilgisi, Türkçe ve İnkılap Tarihi</div>
                        </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="HImgNormal" src={image4} width="100%" alt="derZane.com"/>
                        <img className="HImgMobile" src={mobile4} width="100%" alt="derZane.com"/>
                        <Carousel.Caption>
                        <div className="HeaderText">
                            <div className="HeaderText1">Ünitelere göre testler</div>
                            <div className="HeaderText2">Öğren, uygula ve başar aşamaları</div>
                        </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="HImgNormal" src={image5} width="100%" alt="derZane.com"/>
                        <img className="HImgMobile" src={mobile5} width="100%" alt="derZane.com"/>
                        <Carousel.Caption>
                        <div className="HeaderText">
                            <div className="HeaderText1">Çalışmalarını değerlendir ve karşılaştır</div>
                            <div className="HeaderText2">Gelişmene katkıda bulunacak istatistiksel bilgi</div>
                        </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="HImgNormal" src={image6} width="100%" alt="derZane.com"/>
                        <img className="HImgMobile" src={mobile6} width="100%" alt="derZane.com"/>
                        <Carousel.Caption>
                        <div className="HeaderText">
                            <div className="HeaderText1">Ücretsiz üye ol!</div>
                            <div className="HeaderText2">Hemen deZane'den yararlanmaya başla</div>
                        </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        )
    }
}

export default Header;
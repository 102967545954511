import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';

class Duyurular extends Component {
    componentDidMount() {
        const text = `Duyurular: ${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${window.navigator.language}, ${window.navigator.platform}, ${window.navigator.vendor}, ${window.navigator.appName}, ${window.navigator.userAgent}, ${window.navigator.product}, ${window.navigator.productSub}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render() {
        return (  
            <div>
                <HeaderSub header='Duyurular'/>
                <div className="Page">      
                    <div className="PageText">
                    derZane soru bankasının kullanımı bir süreliğine ÜCRETSİZdir.                    
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Duyurular);
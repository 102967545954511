import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import { Redirect } from 'react-router-dom';
import Header from './Header';
import CoverItem from './CoverItem';

import hakkimizdaImg from './assets/hakkimizda.jpg';
import fiyatlarImg from './assets/fiyatlar.jpg';
import duyurularImg from './assets/duyurular.png';
import sssImg from './assets/sss.jpg';

class AnaSayfa extends Component {
    state = {
        redirect: 'none'
    }

    componentDidMount() {
        const text = `AnaSayfa: ${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${window.navigator.language}, ${window.navigator.platform}, ${window.navigator.vendor}, ${window.navigator.appName}, ${window.navigator.userAgent}, ${window.navigator.product}, ${window.navigator.productSub}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    callPage = (redirect) => {
        this.setState({ redirect });
    }
    render() { 
        if (this.state.redirect === 'none') {
            return (
                <div>
                    <Header />
                    <div className="PageHeader">
                        LGS
                        <span className="PageHeaderAlt"> Hazırlık</span>
                        <div className="PageSubHeader">
                            Matematik, Fen Bilgisi, Türkçe ve İnkılap Tarihi
                        </div>
                    </div>
                    <div className="Cover">
                        <CoverItem
                            id="Hakkımızda"
                            header="Hakkımızda"
                            image={hakkimizdaImg}
                            redirect="/hakkimizda"
                            callPage={this.callPage}
                        />
                        <CoverItem
                            id="Fiyatlar"
                            header="Üyelik Ücreti"
                            image={fiyatlarImg}
                            redirect="/fiyatlandirma"
                            callPage={this.callPage}
                        />
                        <CoverItem
                            id="Duyurular"
                            header="Duyurular"
                            image={duyurularImg}
                            redirect="/duyurular"
                            callPage={this.callPage}
                        />
                        <CoverItem
                            id="Sık Sorulan Sorular"
                            header="Sıkça Sorulan Sorular"
                            image={sssImg}
                            redirect="/sik-sorulan-sorular"
                            callPage={this.callPage}
                        />
                    </div>
                </div>
            );
        }
        return (<Redirect push to={this.state.redirect} />);
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(AnaSayfa);
